<template>
  <div>
    <quick-chat-text
      placeholder="Dummy !"
      v-model="content"
    />
    <div>{{content}}</div>
    <template-text
      placeholder="Tokens ?"
      :tokens="tokens"
      v-model="template"
    />
    <div>{{template}}</div>
  </div>
</template>

<script>
export default {
  components: {
    QuickChatText: () => import('@/components/quick-chat-text'),
    TemplateText: () => import('@/components/template-text')
  },
  data () {
    return {
      content: {},
      tokens: [],
      template: ''
    }
  },
  async mounted () {
    const [reqToken, reqTemplate] = await Promise.all([
      this.$http().get('/templateType/Reminder'),
      this.$http().get('/template?gridName=templateList')
    ])
    this.tokens = reqToken.data.tokens.filter(t => !t.isDeprecated)
    this.template = { html: reqTemplate.data.items.filter(t => t.templatesTranslatedValues.map(tv => tv.name).includes('Reminder'))[0].content }
  }
}
</script>
